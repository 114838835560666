import { all, takeLeading } from 'redux-saga/effects';

import { AppInit } from './features/app-init/app-init';
import { ResolveUserLocation } from './features/resolve-user-location';

export function* initSaga() {
  yield all([
    takeLeading(AppInit.action, AppInit.saga),
    takeLeading(ResolveUserLocation.action, ResolveUserLocation.saga),
  ]);
}
