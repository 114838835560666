import React from 'react';

import Logo from '@assets/img/lp-logo.png';

import { BotAvatarService } from '../../services/bot-avatar-service';

import styles from './LogoScreen.module.scss';

const botAvatarLink = new BotAvatarService();

const LogoScreen = () => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <img src={botAvatarLink.avatar || Logo} alt='LingoPraxis Bot' className={styles.image} />
    </div>
  </div>
);

export default LogoScreen;
